import React from 'react'
import Layout from '../components/_App/Layout'
import Seo from '../components/_App/Seo'
import NavbarTwo from '../components/_App/NavbarTwo'
import PageBanner from '../components/Common/PageBanner'
import PortfolioDetails from '../components/Portfolio/PortfolioDetails'
import Footer from '../components/_App/Footer'
import Copyright from '../components/_App/Copyright'

const PortfolioDetailsPage = () => {
    return (
        <Layout>
            <Seo title="Portfolio Details" />

            <NavbarTwo />

            <PageBanner 
                pageTitle="Project Details" 
                homePageText="Home" 
                homePageUrl="/" 
                activePageText="Project Details" 
            />

            <PortfolioDetails />

            <Footer />

            <Copyright />
        </Layout>
    )
}

export default PortfolioDetailsPage
