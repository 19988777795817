import { Link } from 'gatsby'
import React from 'react'
// Images 
import portfoliodetlsImg from '../../assets/images/inner-images/pd1.jpg'
import portfoliodetlsImg2 from '../../assets/images/inner-images/pd2.jpg'
import portfolioImg from '../../assets/images/portfolio/p10.jpg'
import portfolioImg2 from '../../assets/images/portfolio/p11.jpg'
import portfolioImg3 from '../../assets/images/portfolio/p12.jpg'

const PortfolioDetails = () => {
    return (
        <>
             <>
                <section className="portfolio-details pt-100 pb-70">
                    <div className="container">
                        <img 
                            className="details-main-img" 
                            src={portfoliodetlsImg}
                            alt="portfolio"
                        />
                        <div className="pd-top-area">
                            <div className="row align-items-center">
                                <div className="col-lg-8 col-md-12 col-sm-12 col-12">
                                    <div className="pd-top-text-area">
                                        <h3 className="details-page-title">Digital Marketing Agency Services List for 2021</h3>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra make acumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod.</p>
                                        <p>Standard dummy text ever since the 1500s, when offs an unknown printer took a galley of type and scra make a type specimen book. It has survived not only and thnfve centuries, but also the leap into electro ting, remaining essentially unchanged. It was popularised in the 196 with the release.</p>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra make acumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod.</p>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                                    <div className="pd-info-area pt-30">
                                        <h3>Information</h3>
                                        <ul>
                                            <li><span>Project:</span> <span> Marketing Agency</span></li>
                                            <li><span>Client:</span> <span> Judith Moore</span></li>
                                            <li><span>Duration:</span> <span> 5 Months</span></li>
                                            <li><span>Budget:</span> <span> $685.00</span></li>
                                            <li><span>Location:</span> <span> 32 Street Name, Second Block, New York, USA</span></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="pd-second-area mt-30">
                            <div className="row">
                                <div className="col-lg-5">
                                    <div className="pds-img-area">
                                    <img 
                                        src={portfoliodetlsImg2}
                                        alt="portfolio"
                                    />
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="pds-text-area pl-20">
                                        <h3 className="details-page-title">Project Overview & Results</h3>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed eiusmod tempor incididun dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipisicing elit, eiusmod.</p>
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been standard dummy text ever since the 1500s, when offs an unknown printer took a galley of type and scra make a type specimen book. It has survived not only and thnfve centuries, but also the leap into electro ting, remaining essent publishing software.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>

            <>
                <section className="recent-project pb-100">
                    <div className="container">
                        <div className="default-section-title default-section-title-middle">
                            <h3>Related Projects</h3>
                        </div>
                        <div className="section-content">
                            <div className="row justify-content-center">
                                <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                    <div className="portfolio-card-4">
                                        <img 
                                            src={portfolioImg} 
                                            alt="portfolio"
                                        />
                                        <div className="portfolio-card-4-text-area">
                                            <h4><Link to="/portfolio-details">Customer Management Process</Link></h4>
                                            <p>Fauci has said he believes all states should</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                    <div className="portfolio-card-4">
                                        <img 
                                            src={portfolioImg2} 
                                            alt="portfolio"
                                        />
                                        <div className="portfolio-card-4-text-area">
                                            <h4><Link to="/portfolio-details">Customer Management Process</Link></h4>
                                            <p>Fauci has said he believes all states should</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                    <div className="portfolio-card-4">
                                        <img 
                                            src={portfolioImg3} 
                                            alt="portfolio"
                                        />
                                        <div className="portfolio-card-4-text-area">
                                            <h4><Link to="/portfolio-details">Customer Management Process</Link></h4>
                                            <p>Fauci has said he believes all states should</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        </>
       

        
        







    )
}

export default PortfolioDetails
